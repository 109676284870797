<template>
  <div v-if="showEditAvatar">
    <my-upload
      class="upload-img"
      field="imageFile"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      v-model="showEditAvatar"
      :url="uploadUrl"
      :headers="headers"
      lang-type="ua"
      :lang-ext="{ preview: 'Перегляд' }"
      img-format="png"
    ></my-upload>
  </div>
</template>

<script>
const myUpload = () => import('vue-image-crop-upload/upload-2')

export default {
  props: {
    show: {
      required: true,
      default: false
    }
  },
  data: function () {
    return {
      headers: {
        smail: '*_~',
        Authorization: `Bearer ${this.$cookies.get('_token')}`
      },
      uploadUrl: `${process.env.VUE_APP_BASE_URL}users/image/upload`,
      imgDataUrl: '',
      showEditAvatar: false
    }
  },
  watch: {
    show (val) {
      this.showEditAvatar = val
    },
    showEditAvatar (val) {
      !val && this.$emit('taggleShow')
    }
  },
  components: {
    'my-upload': myUpload
  },
  methods: {
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess (imgDataUrl, field) {
      console.log('-------- crop success --------')
      this.imgDataUrl = imgDataUrl
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess (jsonData, field) {
      console.log('-------- upload success --------')
      if (jsonData.path) {
        this.$emit('uploadHandler', jsonData.path)
        this.showEditAvatar = false
        this.imgDataUrl = ''
      }
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail (status, field) {
      console.log('-------- upload fail --------')
      console.log(status)
      console.log('field: ' + field)
    }
  }
}
</script>

<style lang="scss">
.upload-img {
  .vicp-img-container {
    img {
      max-height: none;
      max-width: none;
    }
  }
}
</style>
